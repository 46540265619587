<template>
  <div class="head">
    <div class="top">
      <div class="icon" @click="toPage"></div>
      <div class="content" @click="toPage">
        <div class="chn h1">
          <div>金</div>
          <div>融</div>
          <div>信</div>
          <div>创</div>
          <div>生</div>
          <div>态</div>
          <div>实</div>
          <div>验</div>
          <div>室</div>
        </div>
        <div class="ehg cp">
          <div>Financial</div>
          <div>Information</div>
          <div>Technology</div>
          <div>Innovation</div>
          <div>Ecological</div>
          <div>Laboratory</div>
        </div>
        <!-- <h1>金融信创生态实验室</h1>
        <p>Financial Information Technology Innovation Ecological Laboratory</p> -->
      </div>
      <div class="search">
        <input
          type="text"
          v-model="keyword"
          placeholder="输入关键字"
          @keyup.enter="search"
        />
        <i class="serch-icon" @click="search"></i>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      keyword: "",
    };
  },
  methods: {
    search() {
      this.$router.push({ path: "search" });
      if (this.keyword) {
        this.$store.dispatch("search/getSearchData", {
          keyword: this.keyword,
          pageNo: 1,
        });
      }
    },
    toPage() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style lang="scss">
@import "./../../assets/style/public.scss";
.head {
  width: 100%;
  background: url("../../assets/images/background.jpg") no-repeat;
  background-size: 100% 100%;
  .top {
    @extend %clearfix;
    width: 1080px;
    margin: 0 auto;
    position: relative;
    padding: 15px 0;
    .icon {
      background: url("../../assets/logo.png") no-repeat;
      background-size: 100% 100%;
      width: 80px;
      height: 80px;
      // border: 1px solid #d8d8d8;
      float: left;
      cursor: pointer;
    }
    .content {
      float: left;
      text-align: left;
      vertical-align: middle;
      margin-top: 9px;
      margin-left: 20px;
      cursor: pointer;
      width: 400px;
      .chn {
        display: flex;
        justify-content: space-between;
      }
      .ehg {
        display: flex;
        justify-content: space-between;
      }
      .h1 {
        font-size: 26px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        // color: #222222;
        color: #ffffff;
        line-height: 37px;
        margin-bottom: 10px;
        // letter-spacing: 28px;
        // letter-spacing: 17px;
      }
      .cp {
        font-size: 12px;
        font-family: FZHTJW--GB1-0, FZHTJW--GB1;
        font-weight: normal;
        // color: #222222;
        color: #ffffff;
        line-height: 16px;
      }
    }
    .search {
      float: left;
      position: absolute;
      right: 0;
      bottom: 9px;
      // 红色
      // input {
      //   padding-left: 10px;
      //   // width: 317px;
      //   width: 277px;
      //   height: 38px;
      //   // border: 1px solid #979797;
      //   border: 1px solid #000000;
      //   color: #333;
      //   font-size: 14px;
      //   background: transparent;
      //   padding-left: 45px;
      // }
      // input::-webkit-input-placeholder {
      //   /* WebKit browsers */
      //   color: #000000;
      // }
      // input:-moz-placeholder {
      //   /* Mozilla Firefox 4 to 18 */
      //   color: #000000;
      // }
      // input::-moz-placeholder {
      //   /* Mozilla Firefox 19+ */
      //   color: #000000;
      // }
      // input:-ms-input-placeholder {
      //   /* Internet Explorer 10+ */
      //   color: #000000;
      // }
      // .serch-icon {
      //   background: url("../../assets/images/search.png") no-repeat;
      //   background-size: 100% 100%;
      //   width: 15px;
      //   height: 15px;
      //   position: absolute;
      //   top: 50%;
      //   // right: 11px;
      //   left: 10px;
      //   margin-top: -7.5px;
      //   cursor: pointer;
      //   &::after {
      //     content: "";
      //     width: 1px;
      //     height: 25px;
      //     background-color: #000000;
      //     position: absolute;
      //     top: -5px;
      //     right: -10px;
      //   }
      // }

      // 蓝色
      input {
        padding-left: 10px;
        // width: 317px;
        width: 277px;
        height: 38px;
        // border: 1px solid #979797;
        border: 1px solid #0092e2;
        color: #ffffff;
        font-size: 14px;
        background: transparent;
        padding-left: 45px;
      }
      input::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #0092e2;
      }
      input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #0092e2;
      }
      input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #0092e2;
      }
      input:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #0092e2;
      }
      .serch-icon {
        background: url("../../assets/images/search.png") no-repeat;
        background-size: 100% 100%;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        // right: 11px;
        left: 10px;
        margin-top: -7.5px;
        cursor: pointer;
        &::after {
          content: "";
          width: 1px;
          height: 25px;
          background-color: #0092e2;
          position: absolute;
          top: -5px;
          right: -10px;
        }
      }
    }
  }
}
</style>
