import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api/install'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { NoticeBar, Swipe, SwipeItem, Lazyload } from 'vant';
import animated from 'animate.css'
Vue.use(animated)

Vue.use(api)
Vue.use(ElementUI);
Vue.use(NoticeBar).use(Swipe).use(SwipeItem).use(Lazyload);
// 公共样式
import '@/assets/style/common.scss'


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 解决每次新开页面都显示顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

router.beforeEach((to, from, next) => {
  next()
})

// 加入百度统计
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next()
})

