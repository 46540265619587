<template>
  <div class="nav">
    <ul class="menu-box">
      <li class="menu" v-for="(item, index) in menuArr" :key="index">
        <a href="javascript:void(0)" @click="toPage(index)"
          ><span>{{ item.name }}</span></a
        >
        <ul class="submenu-box" v-if="item.children">
          <li
            class="submenu"
            v-for="(sitem, sindex) in item.children"
            :key="sindex"
            @click="toPage(index, sindex)"
          >
            <a href="javascript:void(0)">{{ sitem.name }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedIndex: "",
      menuArr: [],
    };
  },
  created() {
    this.getNavList();
  },
  watch: {
    $route: {
      handler: function (route) {
        this.$nextTick(function () {
          let _this = this;
          let name = route.meta.title;
          this.menuArr.forEach((item, index) => {
            if (item.label == name) {
              _this.selectedIndex = item.code;
            }
          });
        });
      },
    },
  },
  methods: {
    async getNavList() {
      let res = await this.$api.second.getNavList();
      function create_Tree(data, pid) {
        pid = pid || 0;
        let list = [];
        data.map(function (item) {
          item.active = 0; // 是否是当前选中的
          item.skip = 1; // 走模版
          if (item.pid == pid) {
            item.children = create_Tree(data, item.id);
            list.push(item);
          }
        });
        return list;
      }
      if (res.status == 200) {
        let tree = create_Tree(res.data);
        tree.unshift({
          name: "首页",
          path: "/",
          skip: 0,
          active: 0,
          children: [],
        });
        // tree.push({
        //   name: "联系我们",
        //   path: "/contact",
        //   skip: 0,
        //   active: 0,
        //   children: [],
        // });
        this.menuArr = tree;
        sessionStorage.setItem("menus", JSON.stringify(this.menuArr));
      }
    },
    // type 0列表 1文章 2联系我们 3通知公告
    toPage(index, sindex) {
      console.log(index, sindex);
      this.menuArr[index].active = 1;
      if (sindex != undefined) {
        this.menuArr[index].children[sindex].active = 1;
      } else {
        if (this.menuArr[index].children.length > 1) {
          this.menuArr[index].children[0].active = 1;
        }
      }
      if (this.menuArr[index].skip == 0) {
        this.$router.push({ path: this.menuArr[index].path });
      } else {
        let param = {
          pid: this.menuArr[index].id,
        };
        if (sindex != undefined) {
          param.sid = this.menuArr[index].children[sindex].id;
          // 如果有子级，取出2级目录的type决定跳转那里
          if (this.menuArr[index].children[sindex].type == 2) {
            this.$router.push({ path: "/contact" }); // 直接跳转联系方式
          } else if (this.menuArr[index].children[sindex].type == 4) {
            this.$router.push({ path: "/video", query: param }); // 直接跳转视频
          } else if (this.menuArr[index].type == 5) {
            this.$router.push({ path: "/download", query: param }); // 直接下载
          } else {
            this.$router.push({ path: "/second", query: param });
          }
        } else {
          console.log(" ---- >", this.menuArr[index].type);
          if (this.menuArr[index].children.length > 0) {
            param.sid = this.menuArr[index].children[0].id;
          }
          // 没有子级 则按1级默认走
          if (this.menuArr[index].type == 2) {
            this.$router.push({ path: "/contact" }); // 直接跳转联系方式
          } else if (this.menuArr[index].type == 4) {
            this.$router.push({ path: "/video", query: param }); // 直接跳转视频
          } else if (this.menuArr[index].type == 5) {
            this.$router.push({ path: "/download", query: param }); // 直接下载
          } else {
            this.$router.push({ path: "/second", query: param });
          }
        }

        // if (this.menuArr[index].children[sindex].type == 2) {
        //   this.$router.push({ path: "/contact" });
        // } else {
        //   let param = {
        //     pid: this.menuArr[index].id,
        //   };
        //   if (sindex != undefined) {
        //     param.sid = this.menuArr[index].children[sindex].id;
        //   } else {
        //     if (this.menuArr[index].children.length > 0) {
        //       param.sid = this.menuArr[index].children[0].id;
        //     }
        //   }
        //   this.$router.push({ path: "/second", query: param });
        // }
      }
    },
  },
};
</script>
<style lang="scss">
@import "./../../assets/style/public.scss";
.nav {
  width: 100%;
  height: 60px;
  background: #c01515;
  .menu-box {
    width: 1080px;
    margin: 0 auto;
    height: 60px;
    @extend %clearfix;
    .menu {
      text-align: center;
      float: left;
      // width: 100px;
      width: 90px;
      height: 60px;
      line-height: 60px;
      // margin-right: 15px;
      // padding-right: 15px;
      a {
        display: inline-block;
        width: 100%;
        span {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 24px;
        }
      }
      &:hover {
        background: #d61e1e;
        .submenu-box {
          display: block;
        }
      }
      .submenu-box {
        position: absolute;
        z-index: 100000;
        max-width: 480px;
        @extend %clearfix;
        display: none;
        opacity: 0.9;
        .submenu {
          height: 45px;
          float: left;
          padding: 0 10px;
          min-width: 100px;
          line-height: 45px;
          background: #d61e1e;
          border-bottom: 1px solid #ffffff;
          a {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
          &:hover {
            background-color: #c01515;
          }
        }
      }
    }
    // .active {
    //   width: 96px;
    //   height: 100%;
    //   background: #d61e1e;
    //   // border-radius: 8px 8px 0px 0px;
    //   // margin-top: 3px;
    //   a {
    //     span {
    //       // color: #222222;
    //     }
    //   }
    // }
  }
}
</style>
