import $ajaxHome from "./../../api/home/index"
const state = {
    searchArr: [],
    total: 0,
    keyword: '',
    pageSize: 0,
    current: 1,
}
const getters = {
    getSearchArr(state) {
        return state.searchArr
    },
    getTotal(state) {
        return state.getTotal
    },
    getKeyword(state) {
        return state.keyword
    },
    getPageSize(state) {
        return state.pageSize
    },
    getCurrent(state) {
        return state.current
    }
}
const mutations = {
    updateSearchArr(state, list) {
        state.searchArr = list
    },
    updateTotal(state, total) {
        state.total = total
    },
    updateKeyword(state, keyword) {
        state.keyword = keyword
    },
    updatePageSize(state, pageSize) {
        state.pageSize = pageSize
    },
    updateCurrent(state, current) {
        state.current = current
    },
}
const actions = {
    async getSearchData(context, obj) {
        let res = await $ajaxHome.search({ name: obj.keyword, pageNo: obj.pageNo })
        sessionStorage.setItem(
            "keyword",
            obj.keyword
        );
        if (res.status == 200) {
            res.data.records.forEach((item) => {
                item.createTime = item.createTime.substr(0, 10);
            });
            context.commit('updateSearchArr', res.data.records)
            context.commit('updateTotal', res.data.total)
            context.commit('updatePageSize', res.data.size)
            context.commit('updateKeyword', obj.keyword)
            context.commit('updateCurrent', res.data.current)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}