export default [
    {
        path: "/", // 路由地址
        redirect: "/home", // 重定向
        meta: { title: "首页" },
    },
    {
        path: '/home',
        name: 'Home',
        meta: { title: "首页" },
        component: () => import('./../views/h5/index/index.vue')
    },
    {
        path: '/list',
        name: 'List',
        meta: { title: "列表" },
        component: () => import('@/views/h5/list/index.vue')
    },
    {
        path: '/detail',
        name: 'Detail',
        meta: { title: "详情" },
        component: () => import('@/views/h5/detail/index.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        meta: { title: "联系我们" },
        component: () => import('@/views/h5/contact/index.vue')
    },
    {
        path: '/video',
        name: 'Video',
        meta: { title: "视频列表" },
        component: () => import('@/views/h5/video/index.vue'),
    },
    {
        path: '/video/details',
        name: 'VideoDetails',
        meta: { title: "视频详情" },
        component: () => import('@/views/h5/video/details.vue'),
    },
    {
        path: '/download',
        name: 'Download',
        meta: { title: "二级页面" },
        component: () => import('@/views/h5/download/index.vue'),
    },
    {
        path: '*',
        name: '404',
        redirect: "/home", // 重定向
    }
]
