import Vue from 'vue'
import VueRouter from 'vue-router'
import PcRouters from './pc'
import H5Routers from './h5'
import { isPC } from '@/utils/index'


Vue.use(VueRouter)

const routes = isPC() ? PcRouters : H5Routers
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}



const router = new VueRouter({
  routes
})

export default router
