import api from '../index'
const baseUrl = process.env.VUE_APP_BASE_URL
export default {
    // 二级分类数据列表
    articleList(params) {
        return api.get(baseUrl + `article/lists/${params.id}/${params.pageNo}`)
    },
    // articleList(params) {
    //     return api.get(baseUrl + `article/list/${params.name}/${params.pageNo}`)
    // },

    // 详情
    articleDetail(id) {
        return api.get(baseUrl + `article/detial/${id}`)
    },

    // 获取文章（左侧文章菜单）
    leftArticle(keyword) {
        return api.get(baseUrl + `article/top/list/${keyword}`)
    },

    /**
     * 下一篇
     * @param {*} params 
     * id: 文章id
     * cname: 例如 cname=实验室概况
     * keyword: 关键字
     */
    articleNext(params) {
        let url = baseUrl + `article/detial/next?id=${params.id}`
        if (params.keyword) {
            url += `&keyword=${params.keyword}`
        }
        if (params.cname) {
            url += `&cname==${params.cname}`
        }
        return api.get(url)
    },
    // 上一篇 
    articlePre(params) {
        let url = baseUrl + `article/detial/pre?id=${params.id}`
        if (params.keyword) {
            url += `&keyword=${params.keyword}`
        }
        if (params.cname) {
            url += `&cname==${params.cname}`
        }
        return api.get(url)
    },
    // 获取广告
    getAdvList() {
        return api.get(baseUrl + `adv/list`)
    },
    // 获取合作伙伴
    getPanList() {
        return api.get(baseUrl + `pan/list`)
    },
    getNavList() {
        return api.get(baseUrl + `category/list/easy`)
    },
    // 获取视频列表
    getVideoList(params) {
        return api.get(baseUrl + `/video/list/${params.pageNo}`)
    },
    getVideoDetial(params) {
        return api.get(baseUrl + `/video/detial/${params.id}`)
    },
    // 获取下载列表
    getDownList(params) {
        return api.get(baseUrl + `/down/list/${params.pageNo}`)
    }

}

