import api from '../index'
const baseUrl = process.env.VUE_APP_BASE_URL
export default {
    // 首页置顶通知
    topList () {
        return api.get(baseUrl + `notice/top/list`)
    },
    // 首页列表通知
    noticeList () {
        return api.get(baseUrl + `notice/list`)
    },
    // 获取轮播图
    sliderList () {
        return api.get(baseUrl + `slider/list`)
    },
    // 友情链接
    linkList () {
        return api.get(baseUrl + `link/list`)
    },
    // 合作伙伴
    cooperationList() {
        return api.get(baseUrl + `partner/list`)
    },
    // 分类
    typeList () {
        return api.get(baseUrl + `article/type/list`)
    },
    // 查询
    search (params) {
        // return api.get(baseUrl + `article/search/${params.name}/${params.pageNo}`)
        return api.get(baseUrl + `article/search?keywords=${params.name}&pageNo=${params.pageNo}`)
    },
    // 联系我们
    getContact () {
        return api.get(baseUrl + `system/config`)
    },
    

    // http://106.13.48.122:8080/www/api/video/list/1

}