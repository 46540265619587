import axios from './axios'

let instance = axios()

export default {
    // get 请求
    get(url, params, headers) {
        let options = {}
        if (params) {
            options.params = params
        }
        if (headers) {
            options.headers = headers
        }
        return instance.get(url, options)
    },

    // post 请求
    post(url, params, headers) {
        let options = {}
        if (headers) {
            options.headers = headers
        }
        return instance.post(url, params, options)
    },

    // put 请求
    put(url, params, headers) {
        let options = {}
        if (headers) {
            options.headers = headers
        }
        return instance.put(url, params, options)
    },

    // delete 请求
    delete(url, params, headers) {
        let options = {}
        if (params) {
            options.params = params
        }
        if (headers) {
            options.headers = headers
        }
        return instance.delete(url, options)
    }
}