export default [
    {
        path: "/", // 路由地址
        redirect: "/home", // 重定向
        meta: { title: "首页" },
    },
    {
        path: '/home',
        name: 'Home',
        meta: { title: "首页" },
        component: () => import('./../views/home/index.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        meta: { title: "联系我们" },
        component: () => import('@/views/contact/index.vue')
    },
    {
        path: '/search',
        name: 'Search',
        meta: { title: "搜索结果" },
        component: () => import('@/views/search/index.vue'),
    },
    {
        path: '/second',
        name: 'Second',
        meta: { title: "二级页面" },
        component: () => import('@/views/second/index.vue'),
    },
    {
        path: '/third',
        name: 'Third',
        meta: { title: "三级页面" },
        component: () => import('@/views/third/index.vue'),
    },
    {
        path: '/video',
        name: 'Video',
        meta: { title: "二级页面" },
        component: () => import('@/views/video/index.vue'),
    },
    {
        path: '/video/details',
        name: 'VideoDetails',
        meta: { title: "三级页面" },
        component: () => import('@/views/video/details.vue'),
    },
    {
        path: '/download',
        name: 'Download',
        meta: { title: "二级页面" },
        component: () => import('@/views/download/index.vue'),
    },
    {
        path: '*',
        name: '404',
        redirect: "/home", // 重定向
    }
]