export const getTreeItem = (data, key, key1) => {
    let res = "";
    function commit (obj) {
        if (obj[key1] == key) {
            res = obj;
        }
    }
    function filterData (obj) {
        commit(obj);
        if (obj.children && obj.children.length > 0) {
            for (let i = 0; i < obj.children.length; i++) {
                filterData(obj.children[i])
            }
        }
    }
    for (let i = 0; i < data.length; i++) {
        filterData(data[i])
    }
    return res;
}

// 判断平台
export const isPC = () => {
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod", "iPad"];
    var flag = true;
    for (let i = 0; i < Agents.length; i++) {
        if (userAgentInfo.indexOf(Agents[i]) > 0) {
            flag = false;
            break;
        }
    }
    return flag;
}