import axios from 'axios'
import browser from './common/browser'
// import { Message } from 'element-ui'

// 创建axios实例
let http = axios.create({
    // headers: {'Content-Type': 'application/json'},
    timeout: 60000
})

// 设置 post、put 默认 Content-Type
http.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
http.defaults.headers.put['Content-Type'] = 'application/json;charset=UTF-8'


// 添加请求拦截器
http.interceptors.request.use(config => {

    // 设置 token
    // if (store.state.token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
    //     config.headers.authorization = store.state.token  //请求头加上token
    // }
    config.method = config.method.toUpperCase();
    // 判断如果请求方式是psot 和 put 就将参数转化为String
    if (config.method === "POST" || config.method === "PUT") {
        config.data = JSON.stringify(config.data);
    } else if (config.method === "GET" && browser.isIE) {
        // 给GET 请求后追加时间戳， 解决IE GET 请求缓存问题
        let symbol = config.url.indexOf('?') >= 0 ? '&' : '?'
        config.url += symbol + '_=' + Date.now()
    }
    // if (config.method === "post" || config.method === "put") {
    //     config.data = JSON.stringify(config.data);
    // } else if (config.method === "get" && browser.isIE) {
    //     // 给GET 请求后追加时间戳， 解决IE GET 请求缓存问题
    //     let symbol = config.url.indexOf('?') >= 0 ? '&' : '?'
    //     config.url += symbol + '_=' + Date.now()
    // }
    // 请求发送前进行处理
    return config; // 请求发送前处理
}, error => {
    // 请求错误处理
    // Message({
    //     message: "服务器异常，请联系管理员",
    //     type: "error",
    //     duration: 5 * 1000
    // })
    return Promise.reject(error)
})

// 添加响应拦截器
http.interceptors.response.use(response => {
    let { data } = response
    return data
}, error => {
    return Promise.reject(error.response.data)
})

/**
 * 创建统一封装过的 axios 实例
 * @return {AxiosInstance}
 */
export default function () {
    return http
}