<template>
  <div id="app">
    <template v-if="pc">
      <my-header></my-header>
      <my-nav></my-nav>
      <!-- <my-notice></my-notice> -->
      <div class="container">
        <router-view v-if="routerAlive" />
      </div>
      <my-footer></my-footer>
    </template>

    <template v-else>
      <myH5Header />
      <router-view class="h5Page" />
    </template>
  </div>
</template>
<script>
import myHeader from "./components/layout/header.vue";
import myH5Header from "@/views/h5/components/header/index.vue";
import myNav from "./components/layout/nav.vue";
import myNotice from "./components/layout/notice.vue";
import myFooter from "./components/layout/footer.vue";
import { isPC } from "@/utils/index";
export default {
  components: {
    myHeader,
    myFooter,
    // myNotice,
    myNav,
    myH5Header,
  },
  name: "App",
  data() {
    return {
      pc: "",
      routerAlive: true,
    };
  },
  // watch: {
  //   // 解决跳转每个新页面默认在顶部显示
  //   "$route.path": function (newVal, oldVal) {
  //     document.querySelector("#goToTop").scrollIntoView(true);
  //   },
  // },
  provide() {
    return {
      routerRefresh: this.routerRefresh,
    };
  },
  created() {
    this.pc = isPC();
  },
  methods: {
    routerRefresh() {
      this.routerAlive = false;
      this.$nextTick(() => {
        this.routerAlive = true;
      });
    },
  },
};
</script>

<style lang="scss">
@import "./assets/style/public.scss";
.container {
  width: 1080px;
  margin: 0 auto;
  margin-top: 20px;
  @extend %clearfix;
  .main {
    min-height: 550px;
    @extend %clearfix;
    background: #f9f9f9;
    .l_box {
      width: 280px;
      float: left;
    }
    .r_box {
      min-height: 550px;
      width: 750px;
      padding-top: 30px;
      padding-left: 50px;
      float: left;
      font-size: 16px;
      color: #222222;
      line-height: 32px;
      background-color: #fff;
    }
  }
}
.h5Page {
  &::after {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    background: #f0f2f5;
  }
}
</style>
