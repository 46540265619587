<template>
  <div class="footer">
    <div class="hd">
      <p>© 2020 金融信创实验室 版权所有</p>
      <p>京ICP备05026712-4号 本网站支持IPV6访问</p>
      <div class="record">
        <p class="gabah">
          <i class="security"></i>
          <span>京公网安备 11010202009095号</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss">
#table_show_content::-webkit-scrollbar {
  width: 0px;
  background: gray;
}
</style>
<style lang="scss" scoped>
@import "./../../assets/style/public.scss";
.footer {
  width: 100%;
  text-align: center;
  padding-top: 0px;
  padding-bottom: 40px;
  float: left;
  .hd {
    width: 1080px;
    margin: 0 auto;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 2px solid #dbdcdb;
  }
  p {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #222222;
    line-height: 17px;
    margin-top: 5px;
  }
  .record {
    position: relative;
    .gabah {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      @extend %clearfix;
      .security {
        @include bgImg("../../assets/images/WechatIMG534.png", 15px, 15px);
        display: inline-block;
        float: left;
        margin-right: 2px;
      }
      span {
        float: left;
      }
    }
  }
}
</style>
