<template>
  <div class="myHeader">
    <span class="icon" @click="showPop"><img src="./menu.png" alt="" /></span>
    <span class="text">金融信创生态实验室</span>
    <el-drawer
      custom-class="drawerMenu"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <div class="list">
        <div
          v-for="(item, idx) in menuArr"
          :key="idx"
          @click.stop="toMenu(idx)"
          :class="{ 'item-a': true, active: item.active == 1 }"
        >
          <span>{{ item.name }}</span>
          <ul
            class="subMenu"
            v-if="item.children.length > 0"
            :style="{ height: item.ulHeight + 'px' }"
          >
            <li
              v-for="(sitem, index) in item.children"
              :key="index"
              @click.stop="toPage(idx, index)"
              :class="{ active: sitem.active == 1 }"
            >
              {{ sitem.name }}
            </li>
          </ul>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuArr: [],
      drawer: false,
      direction: "ttb",
      pId: "A",
      subId: 0,
    };
  },
  created() {
    this.getNavList();
  },
  methods: {
    showPop() {
      this.drawer = true;
    },
    handleClose() {
      this.drawer = false;
    },
    async getNavList() {
      let res = await this.$api.second.getNavList();
      function create_Tree(data, pid) {
        pid = pid || 0;
        let list = [];
        data.map(function (item) {
          item.active = 0; // 是否是当前选中的
          item.skip = 1; // 走模版
          item.ulHeight = 0;
          if (item.pid == pid) {
            item.children = create_Tree(data, item.id);
            list.push(item);
          }
        });
        return list;
      }
      if (res.status == 200) {
        let tree = create_Tree(res.data);
        tree.unshift({
          id: "A",
          name: "首页",
          path: "/",
          skip: 0,
          active: 1,
          ulHeight: 0,
          children: [],
        });
        // tree.push({
        //   id: "B",
        //   name: "联系我们",
        //   path: "/contact",
        //   skip: 0,
        //   active: 0,
        //   ulHeight: 0,
        //   children: [],
        // });
        this.menuArr = tree;
        sessionStorage.setItem("menus", JSON.stringify(this.menuArr));
      }
    },
    toMenu(index) {
      console.log("this.menuArr[index] ");
      if (this.menuArr[index].children.length == 0) {
        if (this.menuArr[index].type == 4) {
          console.log("视频");
          this.$router.push({
            path: "/video",
          });
        } else if (this.menuArr[index].type == 5) {
          console.log("下载");
          this.$router.push({
            path: "/download",
          });
        } else {
          this.$router.push({
            path: "/detail",
            query: { type: 10 }, // 直接显示无数据
          });
        }
      }
      this.pId = this.menuArr[index].id;
      if (this.menuArr[index].children.length > 0) {
        // 有子级的展开
        this.menuArr.forEach((item, i) => {
          item.ulHeight = 0;
          item.active = 0;
        });
        let h = 0;
        this.menuArr[index].children.forEach((item, i) => {
          h += 40;
        });
        this.menuArr[index].ulHeight = h;
      } else {
        // 没有的跳转
        if (this.menuArr[index].id == "A") {
          this.$router.push({ path: "/home" });
        }
        if (this.menuArr[index].id == "B") {
          this.$router.push({ path: "/contact" });
        }
        this.menuArr.forEach((item, i) => {
          item.ulHeight = 0;
          item.active = 0;
          item.children.forEach((subitem, j) => {
            subitem.active = 0;
          });
        });
        this.menuArr[index].active = 1;
        this.drawer = false;
      }
    },

    toPage(index, sindex) {
      this.menuArr[index].children.forEach((item, index) => {
        item.active = 0;
      });
      this.menuArr[index].children[sindex].active = 1;
      if (this.menuArr[index].children[sindex].type == 0) {
        // 跳转列表类型
        let param = {
          pid: this.menuArr[index].id,
          sid: this.menuArr[index].children[sindex].id,
        };
        this.$router.push({ path: "/list", query: param });
      } else if (this.menuArr[index].children[sindex].type == 1) {
        // 跳转文章类型
        this.$router.push({
          path: "/detail",
          query: { type: 0, sid: this.menuArr[index].children[sindex].id },
        });
      } else if (this.menuArr[index].children[sindex].type == 2) {
        // 联系我们
        this.$router.push({ path: "/contact" });
      }
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss">
.myHeader {
  width: 100%;
  height: 55px;
  background: #2c3134;
  text-align: center;
  position: relative;
  float: left;
  .icon {
    width: 55px;
    height: 55px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    img {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -15px;
      margin-left: -15px;
    }
  }
  .text {
    color: #fff;
    line-height: 55px;
  }
  .drawerMenu {
    background: #f0f2f5;
    height: 100% !important;
    .el-drawer__header {
      margin-bottom: 10px;
      span {
        display: none;
      }
    }
    .el-drawer__close-btn {
      float: left;
    }
    .list {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      line-height: 20px;
      text-align: left;
      padding: 0 20px;
      .item-a {
        color: #222222;
        padding: 15px 0;
        border-bottom: 1px solid #e9e9e9;
        .subMenu {
          margin-left: 20px;
          margin-top: 5px;
          height: 100%;
          transition: all 0.5s;
          overflow: hidden;
          li {
            padding: 10px 0;
            font-size: 14px;
            color: #222222;
          }
        }
      }
      .active {
        color: #d61e1e !important;
      }
    }
  }
}
</style>
